$sizes: 12;

@mixin margin-classes {
  @for $i from 1 through $sizes {
    $margin: $i * 0.25rem;
    /* margin #{$margin} */
    .m#{$i} {
      margin: $margin;
    }
    html body .ml#{$i} {
      margin-left: $margin;
    }
    html body .mr#{$i} {
      margin-right: $margin;
    }
    html body .mt#{$i} {
      margin-top: $margin;
    }
    html body .mb#{$i} {
      margin-bottom: $margin;
    }
    html body .mx#{$i} {
      margin-left: $margin;
      margin-right: $margin;
    }
    html body .my#{$i} {
      margin-top: $margin;
      margin-bottom: $margin;
    }
  }
}

@include margin-classes;


@mixin padding-classes {
  @for $i from 1 through $sizes {
    $padding: $i * 0.25rem;
    /* padding #{$padding} */
    html body .p#{$i} {
      padding: $padding;
    }
    html body .pl#{$i} {
      padding-left: $padding;
    }
    html body .pr#{$i} {
      padding-right: $padding;
    }
    html body .pt#{$i} {
      padding-top: $padding;
    }
    html body .pb#{$i} {
      padding-bottom: $padding;
    }
    html body .px#{$i} {
      padding-left: $padding;
      padding-right: $padding;
    }
    html body .py#{$i} {
      padding-top: $padding;
      padding-bottom: $padding;
    }
  }
}

@include padding-classes;

//.form-page {
//  &-title {
//    font-size: 24px;
//    font-weight: 400;
//  }
//}
.m-none {
  margin: 0 !important;
}

.mt-none {
  margin-top: 0 !important;
}

.mr-none {
  margin-right: 0 !important;
}

.ml-none {
  margin-left: 0 !important;
}

.mb-none {
  margin-bottom: 0 !important;
}

.w-100 {
  width: 100%;
}

.mxw-100 {
  max-width: 100%;
}

.mxw-100-force {
  max-width: 100% !important;
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0;
}

.mat-mdc-text-field-wrapper {
  background: transparent !important;
}

.mat-mdc-form-field-focus-overlay {
  background: transparent !important;
}
