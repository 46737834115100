/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzFjMDM2MiIsIj9lcjwjYmJiM2QwIiwiO2VyPCMxMTAyNDZ$LCIlPmBePCNkMGUyZmYiLCI~ZXI8I2YxZjZmZiIsIjtlcjwjYmVkNmZmfiwid2Fybj5gXjwjYWMwMDAwIiwiP2VyPCNlNmIzYjMiLCI7ZXI8IzkyMDAwMH4sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00IiwiZmFtaWx5PElCTSBQbGV4IFNhbnMiLCJ2YXJpYW50PHJlZ3VsYXIiLCJzcGFjaW5nPi0xfSxgQDwoLTMiLCJmYW1pbHk8SUJNIFBsZXggU2Fuc34sYEA8KC0yIiwiZmFtaWx5PElCTSBQbGV4IFNhbnN$LGBAPCgtMSIsImZhbWlseTxJQk0gUGxleCBTYW5zfixgQDxoZWFkbGluZSIsImZhbWlseTxJQk0gUGxleCBTYW5zfixgQDx0aXRsZSIsImZhbWlseTxJQk0gUGxleCBTYW5zfixgQDxzdWIpLTIiLCJmYW1pbHk8SUJNIFBsZXggU2Fuc34sYEA8c3ViKS0xIiwiZmFtaWx5PElCTSBQbGV4IFNhbnN$LGBAPGJvZHktMiIsImZhbWlseTxJQk0gUGxleCBTYW5zfixgQDxib2R5LTEiLCJmYW1pbHk8SUJNIFBsZXggU2Fuc34sYEA8YnV0dG9uIiwiZmFtaWx5PElCTSBQbGV4IFNhbnN$LGBAPGNhcHRpb24iLCJmYW1pbHk8SUJNIFBsZXggU2Fuc34sYEA8aW5wdXQiLCJmYW1pbHk8SUJNIFBsZXggU2FucyIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjEzfQ==
*/

@use '@angular/material' as mat;
@use '@angular/material-experimental' as matx;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
@include mat.core();

$fontConfig: (
  display-4: mat.m2-define-typography-level(112px, 112px, 400, 'Roboto', -0.0089em),
  display-3: mat.m2-define-typography-level(56px, 56px, 400, 'Roboto', -0.0089em),
  display-2: mat.m2-define-typography-level(45px, 48px, 400, 'Roboto', 0.0000em),
  display-1: mat.m2-define-typography-level(34px, 40px, 400, 'Roboto', 0.0074em),
  headline: mat.m2-define-typography-level(24px, 32px, 400, 'Roboto', 0.0000em),
  title: mat.m2-define-typography-level(20px, 32px, 500, 'Roboto', 0.0075em),
  subheading-2: mat.m2-define-typography-level(16px, 28px, 400, 'Roboto', 0.0094em),
  subheading-1: mat.m2-define-typography-level(15px, 24px, 500, 'Roboto', 0.0067em),
  body-2: mat.m2-define-typography-level(14px, 24px, 500, 'Roboto', 0.0179em),
  body-1: mat.m2-define-typography-level(14px, 20px, 400, 'Roboto', 0.0179em),
  button: mat.m2-define-typography-level(14px, 14px, 500, 'Roboto', 0.0893em),
  caption: mat.m2-define-typography-level(12px, 20px, 400, 'Roboto', 0.0333em),
  input: mat.m2-define-typography-level(inherit, 1.125, 400, 'Roboto', 1.5px)
);

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
//@include mat.core($fontConfig);

// Theme Config

body {
  --primary-color: #234352;
  --primary-lighter-color: #000;
  --primary-darker-color: #162b35;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
  --swiper-theme-color: #234352;
  --swiper-pagination-color: #234352;
}

$mat-primary: (
  main: #234352,
  lighter: #000,
  darker: #162b35,
  200: #000, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-primary: mat.m2-define-palette($mat-primary, main, lighter, darker);


body {
  --accent-color: #d0e2ff;
  --accent-lighter-color: #f1f6ff;
  --accent-darker-color: #bed6ff;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$dark-primary-text};
}

$mat-accent: (
  main: #d0e2ff,
  lighter: #f1f6ff,
  darker: #bed6ff,
  200: #d0e2ff, // For slide toggle,
  contrast : (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $dark-primary-text,
  )
);
$theme-accent: mat.m2-define-palette($mat-accent, main, lighter, darker);


body {
  --warn-color: #ac0000;
  --warn-lighter-color: #e6b3b3;
  --warn-darker-color: #920000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
  main: #ac0000,
  lighter: #e6b3b3,
  darker: #920000,
  200: #ac0000, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-warn: mat.m2-define-palette($mat-warn, main, lighter, darker);

$theme: (
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn,
  is-dark: false,
  foreground: $mat-light-theme-foreground,
  background: $mat-light-theme-background,
);

$altTheme: (
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn,
  is-dark: true,
  foreground: $mat-dark-theme-foreground,
  background: $mat-dark-theme-background,
);

$theme: mat.m2-define-light-theme(
    (
      color: (
        primary: $theme-primary,
        accent: $theme-accent,
        warn: $theme-warn,
      ),
      typography: $fontConfig,
    )
);

@include mat.typography-hierarchy($fontConfig);

$theme: mat.m2-define-dark-theme(
    (
      color: (
        primary: $theme-primary,
        accent: $theme-accent,
        warn: $theme-warn,
      ),
      typography: $fontConfig,
    )
);

//@include angular-material-theme($dark-theme);

// Theme Init
@include mat.all-component-themes($theme);


.theme-alternate {
  @include mat.all-component-themes($altTheme);
}


//mat.$theme-legacy-inspection-api-compatibility: true;

// Create a theme with the specified color type and density.
@function create-theme($type: light, $density: 0) {
  @return mat.define-theme((
    color: (
      theme-type: $type,
      primary: mat.$violet-palette,
      tertiary: mat.$violet-palette,
    ),
    typography: (
      brand-family: 'Roboto',
    ),
    density: (
      scale: $density
    ),
  ));
}

// Define the default (light) theme.
$light-theme: create-theme($type: light);

html {
  .mat-expansion-panel {
    box-shadow: none !important;
  }

  .mat-expansion-indicator svg {
    min-width: 24px;
    min-height: 24px;
    fill: #000000 !important;
  }

  body {
    background-color: #F1F5FD;
    .mat-bottom-sheet-container,
    .mat-toolbar,
    .mat-drawer-container {
      background-color: #F1F5FD;
    }
  }

  @include mat.all-component-themes($light-theme);
  .mdc-button,
  .mat-mdc-raised-button, .mat-mdc-raised-button .mdc-button__ripple,
  .mat-mdc-outlined-button {
    border-radius: 12px;
  }

  .swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
  }

  mat-radio-group {
    display: flex;
    flex-direction: column;
  }

  .mat-standard-chip {
    padding: .5em .85em;
    min-height: 2.5em;
  }



  .material-symbols-outlined {

  }

  .mat-list-base.inline-list {
    display: flex;
  }

  .mat-mdc-radio-button .mat-internal-form-field {
    color: var(--primary-color);
  }

  .mat-bottom-sheet-container {
    padding: 0;
    border-radius: 28px 28px 0 0;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    padding: 0 8px;
  }

  .mdc-circular-progress__circle-clipper {
    width: 100%;
  }

  .mdc-list-item--with-leading-icon .mdc-list-item__start {
    margin-right: 16px;
  }

  .mdc-list-item__primary-text {
    text-overflow: initial;
    white-space: break-spaces;
    //overflow: hidden;
  }

  .mdc-list-item__secondary-text {
    text-overflow: initial;
    white-space: break-spaces;
    overflow: auto;
  }

  .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
    height: auto;
  }

  .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
    height: auto;
    min-height: 56px;
  }
}
