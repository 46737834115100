html {
  .mat-mdc-tab .mdc-tab__text-label {
    color: rgba(0, 0, 0, 0.5);

    &:hover {
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .mat-mdc-tab:hover .mdc-tab__text-label {
    color: rgba(0, 0, 0, 0.8);
  }

  .mat-mdc-tab-header-pagination-chevron {
    border-color: inherit;
  }

  .mdc-tab__ripple {
    display: none;
  }
}

.mat-tab-group {
  .mat-mdc-tab-header {
    height: 65px !important;
  }


  .mat-tab-label {
    height: 65px !important; // Altura padrão para todos os tabs
    background-color: #000; // Cor padrão para todos os tabs

    &.mat-tab-label-active {
      // Cor para tab ativo
      &.color-red {
        background-color: #BD0000;
      }

      &.color-orange {
        background-color: #E07F25;
      }

      &.color-green {
        background-color: #0B871F;
      }
    }
  }
}
