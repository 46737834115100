/* You can add global styles to this file, and also import other style files */
:root {
  --success-color: #0B871F;
  --alert-color: #C86000;
  --error-color: #AC0000;

  --black-700: rgba(0, 0, 0, 0.7);
  --spacing-50: 4px;
  --spacing-100: 8px;
  --spacing-150: 12px;
  --spacing-200: 16px;
  --spacing-250: 20px;
  --spacing-300: 24px;
  --spacing-350: 28px;
  --spacing-400: 32px;
  --spacing-450: 36px;
  --spacing-500: 40px;
  --spacing-550: 44px;
  --spacing-600: 48px;
  --sampler-sem-radius-none: 0;
  --sampler-sem-radius-sm: 4px;
  --sampler-sem-radius-lg: 20px;
  --sampler-sem-radius-circle: 100%;
  --sampler-sem-radius-xsm: 2px;
  --sampler-sem-radius-md: 8px;
  --sampler-sem-radius-xmd: 12px;
  --sampler-sem-radius-xxmd: 16px;
  --sampler-theme-border-radius: var(--sampler-sem-radius-sm);
  --sampler-sem-radius-padleft: var(--spacing-150);
  --sampler-comp-radius-scale-max: 30px;
  --sampler-comp-radius-sm-keyhint: var(--sampler-sem-radius-xsm);
  --sampler-comp-radius-sm-ranking-drpdwn: var(--sampler-sem-radius-xsm);
  --sampler-comp-radius-lg-ranking-drpdwn: var(--sampler-sem-radius-xmd);
  --sampler-comp-radius-lg-picchoice: var(--sampler-sem-radius-md);
  --sampler-comp-radius-none-payment: var(--sampler-sem-radius-xmd);
  --sampler-comp-radius-sm-payment: var(--sampler-sem-radius-xmd);
  --sampler-comp-radius-lg-payment: 34px;
  --sampler-comp-radius-lg-matrix: 24px;
  --sampler-comp-radius-scale: var(--sampler-sem-radius-sm);
  --sampler-comp-radius-keyhint: var(--sampler-comp-radius-sm-keyhint);
  --sampler-comp-radius-ranking-drpdwn: var(--sampler-comp-radius-sm-ranking-drpdwn);
  --sampler-comp-radius-picchoice: var(--sampler-sem-radius-sm);
  --sampler-comp-radius-file-upload: var(--sampler-sem-radius-sm);
  --sampler-comp-radius-file-upload-preview: var(--sampler-sem-radius-sm);
  --sampler-comp-radius-payment: var(--sampler-comp-radius-sm-payment);
  --sampler-comp-radius-matrix: var(--sampler-sem-radius-sm);
  --sampler-comp-radius-button: var(--sampler-sem-radius-sm);
  --sampler-comp-radius-button-focus: 6px;
  --sampler-comp-radius-footer: var(--sampler-sem-radius-sm);
  --sampler-comp-padding-footer: var(--spacing-150);
}

@import "./assets/styles/theme.scss";
@import "./assets/styles/typography-custom.scss";
@import "./assets/styles/reset.scss";
@import "./assets/styles/base.scss";
@import "./assets/styles/buttons.scss";
@import "./assets/styles/tabs.scss";

@import '@typeform/embed/build/css/widget.css'; //import widget css
@import '@typeform/embed/build/css/popup.css';

@import "./assets/styles/var.scss";
@import "./assets/styles/step.scss";
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

.material-icons {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;

  .mat-badge-content {
    font-family: 'Roboto', serif;
  }

}

.material-icons-full {
  font-variation-settings: 'FILL' 1,
  'wght' 0,
  'GRAD' 0,
  'opsz' 24

}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

html body {
  .full-dialog {
    background-color: white;
  }


  .mat-mdc-outlined-button:is(.maria) {
    color: #0040a1 !important;
    border-color: #0040a1 !important;
    background: #fff !important;
  }

  .mat-mdc-raised-button:is(.maria) {
    color: #fff !important;
    background-color: #0040a1 !important;
  }

  @media screen and (min-width: 700px) {
    .mat-toolbar {
      max-width: 700px;
      left: 50%;
      position: relative;
      margin-left: -350px;
    }
    .app-sidenav {
      position: fixed;
    }
    .disable-desktop {
      display: block;
    }
  }

  @media screen and (min-width: 1200px) {
    .mat-toolbar {
      max-width: 700px;
      left: 50%;
      position: relative;
      margin-left: -350PX;
    }
    .mat-drawer-content {
      margin-left: 0 !important;
    }
    .app-sidenav {
      position: fixed;
    }
    .disable-desktop {
      display: none;
    }
  }
}

html {
  body {
    .mat-expansion-panel-header {
      padding: 0 16px;

      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      letter-spacing: 0.15px;


      .mat-content {
        align-items: center;
      }
    }
  }
}

.dialog-100 {
  .mat-mdc-dialog-container-with-actions .mat-mdc-dialog-content {
    padding: 0 !important;
  }

  .mat-mdc-dialog-content {
    max-height: 100vh;
    @supports (max-height: 100dvh) {
      max-height: 100dvh;
    }
  }
}

.circle-img {
  border-radius: 50%;
}

.form-custom {
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: #46464F;
    caret-color: #46464F;;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mat-mdc-notch-piece {
    color: #46464F;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-floating-label {
    color: #46464F;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: #46464F;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label {
    color: #46464F;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mat-mdc-notch-piece {
    border-color: #46464F;
  }

  input::placeholder,
  textarea::placeholder {
    color: #46464F !important;
  }
}
