html body {

  /* Common Styles */
  .click-link {
    color: var(--primary-color);
    cursor: pointer;
  }

  .bg-exams,
  .step--form .image.bg-exams,
  .step--form .image.bg-exam-request {
    background: url("../../assets/image/patient/baixar_pedidos_exames.webp") no-repeat center center;
    background-size: cover;
  }

  .bg-exams-pending,
  .step--form .image.bg-exams-pending {
    background: url("../../assets/image/patient/enviar_pedidos_exames.webp") no-repeat center center;
    background-size: cover;
  }

  .step--form .image.bg-exam-preparation {
    background: url("../../assets/image/patient/preparo_exames.webp") no-repeat center center;
    background-size: cover;
  }

  .step--form .image.bg-exam-reason {
    background: url("../../assets/image/patient/motivos_exames.webp") no-repeat center center;
    background-size: cover;
  }

  .card-exams-request {
    min-height: 367px;
    max-width: 600px;
    cursor: pointer;

    h3 {
      font-size: 24px;
      font-weight: 700;
      line-height: 2rem;

      &.color-primary {
        color: var(--primary-color);
      }

      &.color-white {
        color: #fff;
      }
    }
  }

  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 24px;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    gap: 16px;

    &.start {
      align-items: flex-start;
      justify-content: flex-start;
    }

    &-1 {
      display: none;
    }

    @media screen and (min-width: 600px) {
      box-shadow: none;
      border-radius: 16px;
      background: #fff;
      padding: 16px;
      margin-top: 40px;
    }

    @media screen and (max-height: 600px) {
      min-height: 100vh;
      justify-content: flex-start;
    }

    @media screen and (max-width: 600px) {
      min-width: 100vw;
      padding: 0 24px;
      margin: 0;
    }

    &.with-toolbar {
      @media screen and (max-width: 600px) {
        min-height: calc(100vh - 56px);
      }

      &.full {
        @media screen and (max-width: 600px) {
          min-height: 100vh;
        }
      }
    }

    @supports (height: 100dvh) {
      &.with-toolbar {
        @media screen and (max-width: 600px) {
          min-height: calc(100dvh - 56px);
        }

        &.full {
          @media screen and (max-width: 600px) {
            min-height: 100dvh;
          }
        }
      }
    }

    /* Form Step */
    &--form {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1;

      a {
        cursor: pointer;
      }

      @media screen and (max-width: 600px) {
        padding-top: 24px;
      }

      .card-exams-request-lateral-image {
        display: flex;
        gap: 16px;
        cursor: pointer;
        position: relative;

        @media screen and (min-width: 600px) {
          &:hover::before {
            content: "";
            background: rgba(0, 0, 0, 0.04);
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border-radius: 12px;
            z-index: 2;
          }
        }

        .image {
          border-radius: 12px;
          overflow: hidden;
          width: 80px;
          height: 80px;
          min-width: 80px;
          min-height: 80px;
        }

        h3 {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.15px;
          margin-bottom: 4px;
        }

        p {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.6);
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.25px;
        }
      }

      .expand-card {
        .mdc-card {
          background-color: #f1cece;

          @media screen and (min-width: 600px) {
            cursor: pointer;
          }

          &:hover {
            background-color: rgba(241, 206, 206, 0.8);
          }

          .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 16px;
          }

          p {
            color: #18243a;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.2rem;
            letter-spacing: 0.5px;
          }

          ul {
            padding-left: 16px;
            margin-top: 16px;

            li {
              font-size: 14px;
              font-weight: 400;
              line-height: 1.2rem;
              letter-spacing: 0.25px;
              color: #000;
            }
          }
        }
      }

      .image-top {
        width: 256px;
        margin: 0 auto 24px;
      }

      h2 {
        color: #18243a;
        text-align: left;
        width: 100%;
        font-size: 28px;
        font-weight: 400;
        line-height: 2.25rem;

        &.center {
          text-align: center;
        }
      }

      p {
        margin-top: 16px;
        text-align: left;
        width: 100%;
        color: rgba(0, 0, 0, 0.64);
        font-size: 16px;

        &.center {
          text-align: center;
        }

        &.blue {
          color: #303f5a;
        }
      }

      &.center {
        justify-content: center;

        h2,
        image,
        p {
          text-align: center;
        }
      }

      ul {
        margin-top: 24px;
        text-align: left;
        width: 100%;

        li {
          color: rgba(0, 0, 0, 0.64);
          font-size: 16px;
        }
      }

      .action {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
      }

      form {
        margin-top: 60px;
        width: 100%;
      }
    }

    /* First Step */
    &--first {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      flex: 1;
      width: 100%;

      img {
        width: 250px;
      }

      h1 {
        text-align: left;
        width: 100%;

        @media screen and (max-width: 600px) {
          margin-top: 40px;
        }

        &.space {
          margin-top: 40px;
        }
      }

      p {
        color: rgba(0, 0, 0, 0.64);
        margin-top: 16px;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.5px;
        text-align: left;
        width: 100%;
      }

      &.center {
        justify-content: center;

        h1,
        p {
          text-align: center;
        }
      }
    }

    /* Second Step */
    &--second {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      flex: 1;

      //@media screen and (max-width: 600px) {
      //  padding: 0 24px;
      //}

      h2 {
        text-align: left;
        width: 100%;

        @media screen and (max-width: 600px) {
          margin-top: 40px;
        }
      }

      p {
        margin-top: 24px;
        text-align: left;
        width: 100%;
      }

      form {
        margin-top: 60px;
      }
    }

    &.pdf {
      align-items: flex-start;
      justify-content: flex-start;

      @media screen and (min-width: 600px) {
        margin: 0;
        width: 100%;
        max-width: 100%;
        height: calc(100vh - 68px);

        iframe {
          min-height: 100%;
        }
      }
    }

    /* Footer */
    .footer {
      width: 100%;

      @media screen and (max-width: 600px) {
        padding-bottom: 16px;
      }

      @media screen and (min-width: 600px) {
        padding-top: 32px;
      }

      mat-divider {
        margin-bottom: 16px;
      }

      patient-send-exams + button {
        margin-top: 16px;
      }

      button {
        width: 100%;

        & + button {
          margin-top: 16px;
        }

        & + p {
          margin-top: 16px;
          text-align: center;
        }
      }
    }

    /* File List */
    ul.file-list {
      list-style: none;
      padding: 0;

      @media screen and (max-width: 600px) {
        width: calc(100vw - 48px);
      }

      &.short {
        h2 {
          padding: 0;
        }

        li {
          gap: 8px;
          padding: 0;
        }
      }

      h2 {
        font-size: 16px;
        font-weight: 600;
        line-height: 2rem;
        letter-spacing: 0.15px;
        padding: 0;
      }

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        width: 100%;
        padding: 16px 0;

        .mat-icon {
          width: 24px;
        }

        .buttons {
          display: flex;
          justify-content: center;
        }

        .text-list {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          overflow: hidden;

          span {
            width: 100%;
            font-size: 16px;
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.hint {
              font-size: 12px;
              line-height: 1.5rem;
              letter-spacing: 0.4px;
            }

            &.empty {
              font-size: 14px;
              line-height: 1.5rem;
              letter-spacing: 0.4px;
              text-align: center;
            }

            &.title {
              font-size: 16px;
              line-height: 2rem;
              letter-spacing: 0.5px;
              color: #000;
            }
          }
        }
      }
    }

    .card-list {
      &.card-flex {
        display: flex;
        gap: 16px;
      }

      h3 {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        letter-spacing: 0.15px;
        margin-bottom: 4px;
      }

      p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.60);
      }
    }

    @media screen and (max-width: 600px) {
      .footer-float-button {
        position: fixed;
        bottom: 16px;
        width: calc(100% - 48px);
      }

      .button-placeholder {
        height: 48px;
        width: 100%;
      }
    }
  }


  .disclaimer {
    display: flex;
    width: 100%;
    padding: 16px;
    gap: 4px;
    border-radius: 16px;
    background-color: #DAE2FF;
    justify-content: space-between;


    color: #45464F;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.5px;

    &.red {
      color: #AC0000;
    }
  }

}


