html .mat-display-medium, html .mat-typography .mat-display-medium, html .mat-typography h2 {
  font: 500 1.25rem/32px Roboto; // 20px
}

html .mat-display-large, html .mat-typography .mat-display-large, html .mat-typography h1 {
  font: 500 1.5rem/32px Roboto; // 20px
}

html .mat-body-large, html .mat-typography .mat-body-large, html .mat-typography {
  font-size: 1rem; // 16px
  letter-spacing: 0.2506px;
  font-family: "Roboto", sans-serif;
  line-height: 1.25rem;
}

.mat-toolbar {
  color: #000;
}
