
html body {
  --mat-app-background-color: #fff;
  --mat-app-text-color: #162b35;
  --mat-ripple-color: rgba(26, 28, 24, 0.1);
  --mat-option-selected-state-label-text-color: #121f0e;
  --mat-option-label-text-color: #162b35;
  --mat-option-hover-state-layer-color: rgba(26, 28, 24, 0.08);
  --mat-option-focus-state-layer-color: rgba(26, 28, 24, 0.12);
  --mat-option-selected-state-layer-color: #d7e8cd;
  --mat-option-label-text-font: "Roboto", sans-serif;
  --mat-option-label-text-line-height: 1.25rem;
  --mat-option-label-text-size: 1rem;
  --mat-option-label-text-tracking: 0.006rem;
  --mat-option-label-text-weight: 400;
  --mat-full-pseudo-checkbox-selected-icon-color: #234352;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #ffffff;
  --mat-full-pseudo-checkbox-unselected-icon-color: #4283a5;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fff;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: rgba(26, 28, 24, 0.38);
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: rgba(26, 28, 24, 0.38);
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #234352;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: rgba(26, 28, 24, 0.38);
  --mdc-elevated-card-container-color: #fff;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 12px;
  --mdc-outlined-card-container-color: #fff;
  --mdc-outlined-card-outline-color: #c3c8bc;
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-shape: 12px;
  --mdc-outlined-card-outline-width: 1px;
  --mat-card-subtitle-text-color: #162b35;
  --mat-card-title-text-font: Roboto;
  --mat-card-title-text-line-height: 1.75rem;
  --mat-card-title-text-size: 1.25rem;
  --mat-card-title-text-tracking: 0rem;
  --mat-card-title-text-weight: 400;
  --mat-card-subtitle-text-font: "Roboto", sans-serif;
  --mat-card-subtitle-text-line-height: 1.5rem;
  --mat-card-subtitle-text-size: 1rem;
  --mat-card-subtitle-text-tracking: 0.009rem;
  --mat-card-subtitle-text-weight: 500;
  --mdc-linear-progress-active-indicator-color: #234352;
  --mdc-linear-progress-track-color: #dfe4d7;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0px;
  --mdc-plain-tooltip-container-color: #2f312d;
  --mdc-plain-tooltip-supporting-text-color: #f1f1eb;
  --mdc-plain-tooltip-supporting-text-line-height: 1rem;
  --mdc-plain-tooltip-supporting-text-font: "Roboto", sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 0.75rem;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.025rem;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #234352;
  --mdc-filled-text-field-focus-active-indicator-color: #234352;
  --mdc-filled-text-field-focus-label-text-color: #234352;
  --mdc-filled-text-field-container-color: #dfe4d7;
  --mdc-filled-text-field-disabled-container-color: rgba(26, 28, 24, 0.04);
  --mdc-filled-text-field-label-text-color: #4283a5;
  --mdc-filled-text-field-hover-label-text-color: #4283a5;
  --mdc-filled-text-field-disabled-label-text-color: rgba(26, 28, 24, 0.38);
  --mdc-filled-text-field-input-text-color: #162b35;
  --mdc-filled-text-field-disabled-input-text-color: rgba(26, 28, 24, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: #4283a5;
  --mdc-filled-text-field-error-hover-label-text-color: #410002;
  --mdc-filled-text-field-error-focus-label-text-color: #ba1a1a;
  --mdc-filled-text-field-error-label-text-color: #ba1a1a;
  --mdc-filled-text-field-active-indicator-color: #4283a5;
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(26, 28, 24, 0.38);
  --mdc-filled-text-field-hover-active-indicator-color: #162b35;
  --mdc-filled-text-field-error-active-indicator-color: #ba1a1a;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ba1a1a;
  --mdc-filled-text-field-error-hover-active-indicator-color: #410002;
  --mdc-filled-text-field-label-text-font: "Roboto", sans-serif;
  --mdc-filled-text-field-label-text-size: 1rem;
  --mdc-filled-text-field-label-text-tracking: 0.031rem;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-caret-color: #234352;
  --mdc-outlined-text-field-focus-outline-color: #234352;
  --mdc-outlined-text-field-focus-label-text-color: #234352;
  --mdc-outlined-text-field-label-text-color: #4283a5;
  --mdc-outlined-text-field-hover-label-text-color: #162b35;
  --mdc-outlined-text-field-disabled-label-text-color: rgba(26, 28, 24, 0.38);
  --mdc-outlined-text-field-input-text-color: #162b35;
  --mdc-outlined-text-field-disabled-input-text-color: rgba(26, 28, 24, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: #4283a5;
  --mdc-outlined-text-field-error-focus-label-text-color: #ba1a1a;
  --mdc-outlined-text-field-error-label-text-color: #ba1a1a;
  --mdc-outlined-text-field-error-hover-label-text-color: #410002;
  --mdc-outlined-text-field-outline-color: #73796e;
  --mdc-outlined-text-field-disabled-outline-color: rgba(26, 28, 24, 0.12);
  --mdc-outlined-text-field-hover-outline-color: #162b35;
  --mdc-outlined-text-field-error-focus-outline-color: #ba1a1a;
  --mdc-outlined-text-field-error-hover-outline-color: #410002;
  --mdc-outlined-text-field-error-outline-color: #ba1a1a;
  --mdc-outlined-text-field-label-text-font: "Roboto", sans-serif;
  --mdc-outlined-text-field-label-text-size: 1rem;
  --mdc-outlined-text-field-label-text-tracking: 0.031rem;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mat-form-field-focus-select-arrow-color: #234352;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(26, 28, 24, 0.38);
  --mat-form-field-state-layer-color: #162b35;
  --mat-form-field-error-text-color: #ba1a1a;
  --mat-form-field-select-option-text-color: #162b35;
  --mat-form-field-select-disabled-option-text-color: rgba(26, 28, 24, 0.38);
  --mat-form-field-leading-icon-color: #4283a5;
  --mat-form-field-disabled-leading-icon-color: rgba(26, 28, 24, 0.38);
  --mat-form-field-trailing-icon-color: #4283a5;
  --mat-form-field-disabled-trailing-icon-color: rgba(26, 28, 24, 0.38);
  --mat-form-field-error-focus-trailing-icon-color: #ba1a1a;
  --mat-form-field-error-hover-trailing-icon-color: #410002;
  --mat-form-field-error-trailing-icon-color: #ba1a1a;
  --mat-form-field-enabled-select-arrow-color: #4283a5;
  --mat-form-field-disabled-select-arrow-color: rgba(26, 28, 24, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-container-text-font: "Roboto", sans-serif;
  --mat-form-field-container-text-line-height: 1.5rem;
  --mat-form-field-container-text-size: 1rem;
  --mat-form-field-container-text-tracking: 0.031rem;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-subscript-text-font: "Roboto", sans-serif;
  --mat-form-field-subscript-text-line-height: 1rem;
  --mat-form-field-subscript-text-size: 0.75rem;
  --mat-form-field-subscript-text-tracking: 0.025rem;
  --mat-form-field-subscript-text-weight: 400;
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
  --mat-form-field-focus-state-layer-opacity: 0;
  --mat-select-panel-background-color: #eeeee8;
  --mat-select-enabled-trigger-text-color: #162b35;
  --mat-select-disabled-trigger-text-color: rgba(26, 28, 24, 0.38);
  --mat-select-placeholder-text-color: #4283a5;
  --mat-select-enabled-arrow-color: #4283a5;
  --mat-select-disabled-arrow-color: rgba(26, 28, 24, 0.38);
  --mat-select-focused-arrow-color: #234352;
  --mat-select-invalid-arrow-color: #ba1a1a;
  --mat-select-trigger-text-font: "Roboto", sans-serif;
  --mat-select-trigger-text-line-height: 1.5rem;
  --mat-select-trigger-text-size: 1rem;
  --mat-select-trigger-text-tracking: 0.031rem;
  --mat-select-trigger-text-weight: 400;
  --mat-select-arrow-transform: translateY(-8px);
  --mat-select-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: #eeeee8;
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-color: #fff;
  --mdc-dialog-subhead-color: #000;
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-dialog-subhead-font: Roboto;
  --mdc-dialog-subhead-line-height: 2rem;
  --mdc-dialog-subhead-size: 1.5rem;
  --mdc-dialog-subhead-weight: 400;
  --mdc-dialog-subhead-tracking: 0rem;
  --mdc-dialog-supporting-text-font: "Roboto", sans-serif;
  --mdc-dialog-supporting-text-line-height: 1.25rem;
  --mdc-dialog-supporting-text-size: 0.875rem;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.016rem;
  --mdc-dialog-container-shape: 28px;
  --mat-dialog-container-max-width: 560px;
  --mat-dialog-container-small-max-width: calc(100vw - 32px);
  --mat-dialog-container-min-width: 280px;
  --mat-dialog-actions-alignment: flex-end;
  --mat-dialog-actions-padding: 16px 24px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px 0;
  --mat-dialog-headline-padding: 6px 24px 13px;
  --mdc-chip-outline-color: #73796e;
  --mdc-chip-disabled-outline-color: rgba(26, 28, 24, 0.12);
  --mdc-chip-focus-outline-color: #4283a5;
  --mdc-chip-hover-state-layer-opacity: 0.08;
  --mdc-chip-selected-hover-state-layer-opacity: 0.08;
  --mdc-chip-disabled-label-text-color: rgba(26, 28, 24, 0.38);
  --mdc-chip-elevated-selected-container-color: #d7e8cd;
  --mdc-chip-flat-disabled-selected-container-color: rgba(26, 28, 24, 0.12);
  --mdc-chip-focus-state-layer-color: #4283a5;
  --mdc-chip-hover-state-layer-color: #4283a5;
  --mdc-chip-selected-hover-state-layer-color: #121f0e;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: #121f0e;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #4283a5;
  --mdc-chip-selected-label-text-color: #121f0e;
  --mdc-chip-with-icon-icon-color: #4283a5;
  --mdc-chip-with-icon-disabled-icon-color: #162b35;
  --mdc-chip-with-icon-selected-icon-color: #121f0e;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #162b35;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #4283a5;
  --mdc-chip-label-text-font: "Roboto", sans-serif;
  --mdc-chip-label-text-line-height: 1.25rem;
  --mdc-chip-label-text-size: 0.875rem;
  --mdc-chip-label-text-tracking: 0.006rem;
  --mdc-chip-label-text-weight: 500;
  --mdc-chip-container-height: 32px;
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 8px;
  --mdc-chip-with-avatar-avatar-size: 24px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 1px;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 0.38;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 0.38;
  --mdc-chip-with-icon-disabled-icon-opacity: 0.38;
  --mat-chip-trailing-action-state-layer-color: #4283a5;
  --mat-chip-selected-trailing-action-state-layer-color: #121f0e;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0.08;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0.12;
  --mat-chip-selected-disabled-trailing-icon-color: #162b35;
  --mat-chip-selected-trailing-icon-color: #121f0e;
  --mat-chip-disabled-container-opacity: 1;
  --mat-chip-trailing-action-opacity: 1;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-checkbox-label-text-color: #162b35;
  --mat-checkbox-label-text-font: "Roboto", sans-serif;
  --mat-checkbox-label-text-line-height: 1.25rem;
  --mat-checkbox-label-text-size: 0.875rem;
  --mat-checkbox-label-text-tracking: 0.016rem;
  --mat-checkbox-label-text-weight: 400;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.08;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.12;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.08;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.12;
  --mdc-switch-selected-focus-state-layer-color: #234352;
  --mdc-switch-selected-handle-color: #ffffff;
  --mdc-switch-selected-hover-state-layer-color: #234352;
  --mdc-switch-selected-pressed-state-layer-color: #234352;
  --mdc-switch-selected-focus-handle-color: #d0e2ff;
  --mdc-switch-selected-hover-handle-color: #d0e2ff;
  --mdc-switch-selected-pressed-handle-color: #d0e2ff;
  --mdc-switch-selected-focus-track-color: #234352;
  --mdc-switch-selected-hover-track-color: #234352;
  --mdc-switch-selected-pressed-track-color: #234352;
  --mdc-switch-selected-track-color: #234352;
  --mdc-switch-disabled-selected-handle-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #162b35;
  --mdc-switch-disabled-selected-track-color: #162b35;
  --mdc-switch-disabled-unselected-handle-color: #162b35;
  --mdc-switch-disabled-unselected-icon-color: #dfe4d7;
  --mdc-switch-disabled-unselected-track-color: #dfe4d7;
  --mdc-switch-selected-icon-color: #002200;
  --mdc-switch-unselected-focus-handle-color: #4283a5;
  --mdc-switch-unselected-focus-state-layer-color: #162b35;
  --mdc-switch-unselected-focus-track-color: #dfe4d7;
  --mdc-switch-unselected-handle-color: #73796e;
  --mdc-switch-unselected-hover-handle-color: #4283a5;
  --mdc-switch-unselected-hover-state-layer-color: #162b35;
  --mdc-switch-unselected-hover-track-color: #dfe4d7;
  --mdc-switch-unselected-icon-color: #dfe4d7;
  --mdc-switch-unselected-pressed-handle-color: #4283a5;
  --mdc-switch-unselected-pressed-state-layer-color: #162b35;
  --mdc-switch-unselected-pressed-track-color: #dfe4d7;
  --mdc-switch-unselected-track-color: #dfe4d7;
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-shape: 9999px;
  --mdc-switch-selected-icon-size: 16px;
  --mdc-switch-track-height: 32px;
  --mdc-switch-track-shape: 9999px;
  --mdc-switch-track-width: 52px;
  --mdc-switch-unselected-icon-size: 16px;
  --mdc-switch-state-layer-size: 40px;
  --mat-switch-track-outline-color: #73796e;
  --mat-switch-disabled-unselected-track-outline-color: #162b35;
  --mat-switch-disabled-selected-handle-opacity: 1;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 16px;
  --mat-switch-selected-handle-size: 24px;
  --mat-switch-pressed-handle-size: 28px;
  --mat-switch-with-icon-handle-size: 24px;
  --mat-switch-selected-handle-horizontal-margin: 0 24px;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0 24px;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0 22px;
  --mat-switch-unselected-handle-horizontal-margin: 0 8px;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0 4px;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0 2px;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 0;
  --mat-switch-visible-track-transition: opacity 75ms;
  --mat-switch-hidden-track-transition: opacity 75ms;
  --mat-switch-track-outline-width: 2px;
  --mat-switch-selected-track-outline-width: 0;
  --mat-switch-disabled-unselected-track-outline-width: 2px;
  --mdc-radio-disabled-selected-icon-color: #162b35;
  --mdc-radio-disabled-unselected-icon-color: #162b35;
  --mdc-radio-unselected-hover-icon-color: #162b35;
  --mdc-radio-unselected-icon-color: #4283a5;
  --mdc-radio-unselected-pressed-icon-color: #162b35;
  --mdc-radio-selected-focus-icon-color: #234352;
  --mdc-radio-selected-hover-icon-color: #234352;
  --mdc-radio-selected-icon-color: #234352;
  --mdc-radio-selected-pressed-icon-color: #234352;
  --mdc-radio-state-layer-size: 40px;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mat-radio-ripple-color: #162b35;
  --mat-radio-checked-ripple-color: #234352;
  --mat-radio-disabled-label-color: rgba(26, 28, 24, 0.38);
  --mat-radio-touch-target-display: block;
  --mdc-slider-handle-color: #234352;
  --mdc-slider-focus-handle-color: #234352;
  --mdc-slider-hover-handle-color: #234352;
  --mdc-slider-active-track-color: #234352;
  --mdc-slider-inactive-track-color: #dfe4d7;
  --mdc-slider-with-tick-marks-inactive-container-color: #4283a5;
  --mdc-slider-with-tick-marks-active-container-color: #ffffff;
  --mdc-slider-disabled-active-track-color: #162b35;
  --mdc-slider-disabled-handle-color: #162b35;
  --mdc-slider-disabled-inactive-track-color: #162b35;
  --mdc-slider-label-container-color: #234352;
  --mdc-slider-label-label-text-color: #ffffff;
  --mdc-slider-with-overlap-handle-outline-color: #ffffff;
  --mdc-slider-with-tick-marks-disabled-container-color: #162b35;
  --mdc-slider-handle-elevation: 1;
  --mdc-slider-handle-shadow-color: #000000;
  --mdc-slider-label-label-text-font: "Roboto", sans-serif;
  --mdc-slider-label-label-text-size: 0.75rem;
  --mdc-slider-label-label-text-line-height: 1rem;
  --mdc-slider-label-label-text-tracking: 0.031rem;
  --mdc-slider-label-label-text-weight: 500;
  --mdc-slider-active-track-height: 4px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 9999px;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.38;
  --mdc-slider-with-tick-marks-container-shape: 9999px;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.38;
  --mat-slider-ripple-color: #234352;
  --mat-slider-hover-state-layer-color: rgba(28, 3, 98, 0.05);
  --mat-slider-focus-state-layer-color: rgba(28, 3, 98, 0.2);
  --mat-slider-value-indicator-width: 28px;
  --mat-slider-value-indicator-height: 28px;
  --mat-slider-value-indicator-caret-display: none;
  --mat-slider-value-indicator-border-radius: 50% 50% 50% 0;
  --mat-slider-value-indicator-padding: 0;
  --mat-slider-value-indicator-text-transform: rotate(45deg);
  --mat-slider-value-indicator-container-transform: translateX(-50%) rotate(-45deg);
  --mat-slider-value-indicator-opacity: 1;
  --mat-menu-item-label-text-color: #162b35;
  --mat-menu-item-icon-color: #4283a5;
  --mat-menu-item-hover-state-layer-color: rgba(26, 28, 24, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(26, 28, 24, 0.12);
  --mat-menu-container-color: #eeeee8;
  --mat-menu-divider-color: #dfe4d7;
  --mat-menu-item-label-text-font: "Roboto", sans-serif;
  --mat-menu-item-label-text-size: 0.875rem;
  --mat-menu-item-label-text-tracking: 0.006rem;
  --mat-menu-item-label-text-line-height: 1.25rem;
  --mat-menu-item-label-text-weight: 500;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 8px;
  --mat-menu-divider-top-spacing: 8px;
  --mat-menu-item-spacing: 12px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 12px;
  --mat-menu-item-trailing-spacing: 12px;
  --mat-menu-item-with-icon-leading-spacing: 12px;
  --mat-menu-item-with-icon-trailing-spacing: 12px;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: #d0e2ff;
  --mdc-list-list-item-disabled-state-layer-color: #162b35;
  --mdc-list-list-item-disabled-state-layer-opacity: 0.12;
  --mdc-list-list-item-label-text-color: #000;
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: #000;
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-trailing-icon-color: #4283a5;
  --mdc-list-list-item-selected-trailing-icon-color: #234352;
  --mdc-list-list-item-disabled-label-text-color: #162b35;
  --mdc-list-list-item-disabled-leading-icon-color: #162b35;
  --mdc-list-list-item-disabled-trailing-icon-color: #162b35;
  --mdc-list-list-item-hover-label-text-color: #162b35;
  --mdc-list-list-item-focus-label-text-color: #162b35;
  --mdc-list-list-item-hover-state-layer-color: #162b35;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.54);

  .mdc-list-item--with-leading-icon:hover .mdc-list-item__start {
    color: var(--mdc-list-list-item-hover-leading-icon-color);
  }

  --mdc-list-list-item-focus-state-layer-color: #162b35;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mdc-list-list-item-label-text-font: "Roboto", sans-serif;
  --mdc-list-list-item-label-text-line-height: 1.5rem;
  --mdc-list-list-item-label-text-size: 1rem;
  --mdc-list-list-item-label-text-tracking: 0.031rem;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: "Roboto", sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 1.25rem;
  --mdc-list-list-item-supporting-text-size: 0.875rem;
  --mdc-list-list-item-supporting-text-tracking: 0.016rem;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: "Roboto", sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 1rem;
  --mdc-list-list-item-trailing-supporting-text-size: 0.688rem;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.031rem;
  --mdc-list-list-item-trailing-supporting-text-weight: 500;
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mdc-list-list-item-container-shape: 0px;
  --mdc-list-list-item-leading-avatar-shape: 9999px;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-label-text-opacity: 0.3;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: #d7e8cd;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 16px;
  --mat-list-active-indicator-shape: 9999px;
  --mat-paginator-container-text-color: #162b35;
  --mat-paginator-container-background-color: #fff;
  --mat-paginator-enabled-icon-color: #4283a5;
  --mat-paginator-disabled-icon-color: rgba(26, 28, 24, 0.38);
  --mat-paginator-container-text-font: "Roboto", sans-serif;
  --mat-paginator-container-text-line-height: 1rem;
  --mat-paginator-container-text-size: 0.75rem;
  --mat-paginator-container-text-tracking: 0.025rem;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 0.75rem;
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-color: #234352;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: #dfe4d7;
  --mat-tab-header-pagination-icon-color: #162b35;
  --mat-tab-header-inactive-label-text-color: #162b35;
  --mat-tab-header-active-label-text-color: #162b35;
  --mat-tab-header-active-ripple-color: #162b35;
  --mat-tab-header-inactive-ripple-color: #162b35;
  --mat-tab-header-inactive-focus-label-text-color: #162b35;
  --mat-tab-header-inactive-hover-label-text-color: #162b35;
  --mat-tab-header-active-focus-label-text-color: #162b35;
  --mat-tab-header-active-hover-label-text-color: #162b35;
  --mat-tab-header-active-focus-indicator-color: #234352;
  --mat-tab-header-active-hover-indicator-color: #234352;
  --mat-tab-header-label-text-font: "Roboto", sans-serif;
  --mat-tab-header-label-text-size: 0.875rem;
  --mat-tab-header-label-text-tracking: 0.006rem;
  --mat-tab-header-label-text-line-height: 1.25rem;
  --mat-tab-header-label-text-weight: 500;
  --mat-tab-header-divider-height: 1px;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.08;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.12;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.08;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.12;
  --mdc-checkbox-disabled-selected-icon-color: rgba(26, 28, 24, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(26, 28, 24, 0.38);
  --mdc-checkbox-selected-checkmark-color: #ffffff;
  --mdc-checkbox-selected-focus-icon-color: #234352;
  --mdc-checkbox-selected-hover-icon-color: #234352;
  --mdc-checkbox-selected-icon-color: #234352;
  --mdc-checkbox-selected-pressed-icon-color: #234352;
  --mdc-checkbox-unselected-focus-icon-color: #162b35;
  --mdc-checkbox-unselected-hover-icon-color: #162b35;
  --mdc-checkbox-unselected-icon-color: #4283a5;
  --mdc-checkbox-unselected-pressed-icon-color: #162b35;
  --mdc-checkbox-selected-focus-state-layer-color: #234352;
  --mdc-checkbox-selected-hover-state-layer-color: #234352;
  --mdc-checkbox-selected-pressed-state-layer-color: #162b35;
  --mdc-checkbox-unselected-focus-state-layer-color: #162b35;
  --mdc-checkbox-unselected-hover-state-layer-color: #162b35;
  --mdc-checkbox-unselected-pressed-state-layer-color: #234352;
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-disabled-label-color: rgba(26, 28, 24, 0.38);
  --mat-checkbox-touch-target-display: block;
  --mdc-text-button-label-text-color: #234352;
  --mdc-text-button-disabled-label-text-color: rgba(26, 28, 24, 0.38);
  --mdc-text-button-label-text-font: "Roboto", sans-serif;
  --mdc-text-button-label-text-size: 0.875rem;
  --mdc-text-button-label-text-tracking: 0.006rem;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-container-height: 40px;
  --mdc-text-button-container-shape: 9999px;
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #234352;
  --mdc-protected-button-disabled-container-color: rgba(26, 28, 24, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(26, 28, 24, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000000;
  --mdc-protected-button-label-text-font: "Roboto", sans-serif;
  --mdc-protected-button-label-text-size: 0.875rem;
  --mdc-protected-button-label-text-tracking: 0.006rem;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-container-height: 40px;
  --mdc-protected-button-container-shape: 9999px;
  --mdc-filled-button-container-color: #234352;
  --mdc-filled-button-label-text-color: #ffffff;
  --mdc-filled-button-disabled-container-color: rgba(26, 28, 24, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(26, 28, 24, 0.38);
  --mdc-filled-button-label-text-font: "Roboto", sans-serif;
  --mdc-filled-button-label-text-size: 0.875rem;
  --mdc-filled-button-label-text-tracking: 0.006rem;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-container-height: 40px;
  --mdc-filled-button-container-shape: 9999px;
  --mdc-outlined-button-disabled-outline-color: rgba(26, 28, 24, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(26, 28, 24, 0.38);
  --mdc-outlined-button-label-text-color: #234352;
  --mdc-outlined-button-outline-color: #73796e;
  --mdc-outlined-button-label-text-font: "Roboto", sans-serif;
  --mdc-outlined-button-label-text-size: 0.875rem;
  --mdc-outlined-button-label-text-tracking: 0.006rem;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-container-height: 40px;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 12px;
  --mat-text-button-state-layer-color: #234352;
  --mat-text-button-disabled-state-layer-color: #4283a5;
  --mat-text-button-ripple-color: rgba(28, 3, 98, 0.12);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mat-text-button-touch-target-display: block;
  --mat-text-button-horizontal-padding: 12px;
  --mat-text-button-with-icon-horizontal-padding: 16px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: -4px;
  --mat-protected-button-state-layer-color: #234352;
  --mat-protected-button-disabled-state-layer-color: #4283a5;
  --mat-protected-button-ripple-color: rgba(28, 3, 98, 0.12);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mat-protected-button-touch-target-display: block;
  --mat-protected-button-horizontal-padding: 24px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -8px;
  --mat-filled-button-state-layer-color: #ffffff;
  --mat-filled-button-disabled-state-layer-color: #4283a5;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.12);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mat-filled-button-touch-target-display: block;
  --mat-filled-button-horizontal-padding: 24px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -8px;
  --mat-outlined-button-state-layer-color: #234352;
  --mat-outlined-button-disabled-state-layer-color: #4283a5;
  --mat-outlined-button-ripple-color: rgba(28, 3, 98, 0.12);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mat-outlined-button-touch-target-display: block;
  --mat-outlined-button-horizontal-padding: 24px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -8px;
  --mdc-icon-button-icon-color: #000;
  --mdc-icon-button-disabled-icon-color: rgba(26, 28, 24, 0.38);
  --mdc-icon-button-state-layer-size: 40px;
  --mdc-icon-button-icon-size: 24px;
  --mat-icon-button-state-layer-color: #4283a5;
  --mat-icon-button-disabled-state-layer-color: #4283a5;
  --mat-icon-button-ripple-color: rgba(67, 72, 63, 0.12);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mat-icon-button-touch-target-display: block;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000000;
  --mdc-extended-fab-label-text-font: "Roboto", sans-serif;
  --mdc-extended-fab-label-text-size: 0.875rem;
  --mdc-extended-fab-label-text-tracking: 0.006rem;
  --mdc-extended-fab-label-text-weight: 500;
  --mdc-extended-fab-container-height: 56px;
  --mdc-extended-fab-container-shape: 16px;
  --mdc-fab-container-color: #d0e2ff;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000000;
  --mdc-fab-container-shape: 16px;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-color: #d0e2ff;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000000;
  --mdc-fab-small-container-shape: 12px;
  --mdc-fab-small-icon-size: 24px;
  --mat-fab-foreground-color: #002200;
  --mat-fab-state-layer-color: #002200;
  --mat-fab-ripple-color: rgba(0, 34, 0, 0.12);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(26, 28, 24, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(26, 28, 24, 0.38);
  --mat-fab-touch-target-display: block;
  --mat-fab-small-foreground-color: #002200;
  --mat-fab-small-state-layer-color: #002200;
  --mat-fab-small-ripple-color: rgba(0, 34, 0, 0.12);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(26, 28, 24, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(26, 28, 24, 0.38);
  --mdc-snackbar-container-color: #2f312d;
  --mdc-snackbar-supporting-text-color: #f1f1eb;
  --mdc-snackbar-supporting-text-font: "Roboto", sans-serif;
  --mdc-snackbar-supporting-text-line-height: 1.25rem;
  --mdc-snackbar-supporting-text-size: 0.875rem;
  --mdc-snackbar-supporting-text-weight: 400;
  --mdc-snackbar-container-shape: 4px;
  --mat-snack-bar-button-color: #bed6ff;
  --mat-table-background-color: #fff;
  --mat-table-header-headline-color: #162b35;
  --mat-table-row-item-label-text-color: #162b35;
  --mat-table-row-item-outline-color: #c3c8bc;
  --mat-table-header-headline-font: "Roboto", sans-serif;
  --mat-table-header-headline-line-height: 1.25rem;
  --mat-table-header-headline-size: 0.875rem;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.006rem;
  --mat-table-row-item-label-text-font: "Roboto", sans-serif;
  --mat-table-row-item-label-text-line-height: 1.25rem;
  --mat-table-row-item-label-text-size: 0.875rem;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.016rem;
  --mat-table-footer-supporting-text-font: "Roboto", sans-serif;
  --mat-table-footer-supporting-text-line-height: 1.25rem;
  --mat-table-footer-supporting-text-size: 0.875rem;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.016rem;
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
  --mat-table-row-item-outline-width: 1px;
  --mdc-circular-progress-active-indicator-color: #234352;
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mat-badge-background-color: #ba1a1a;
  --mat-badge-text-color: #ffffff;
  --mat-badge-disabled-state-background-color: rgba(186, 26, 26, 0.38);
  --mat-badge-disabled-state-text-color: #ffffff;
  --mat-badge-text-font: "Roboto", sans-serif;
  --mat-badge-text-size: 0.688rem;
  --mat-badge-text-weight: 500;
  --mat-badge-large-size-text-size: 0.688rem;
  --mat-badge-container-shape: 9999px;
  --mat-badge-container-size: 16px;
  --mat-badge-small-size-container-size: 6px;
  --mat-badge-large-size-container-size: 16px;
  --mat-badge-legacy-container-size: unset;
  --mat-badge-legacy-small-size-container-size: unset;
  --mat-badge-legacy-large-size-container-size: unset;
  --mat-badge-container-offset: -12px 0;
  --mat-badge-small-size-container-offset: -6px 0;
  --mat-badge-large-size-container-offset: -12px 0;
  --mat-badge-container-overlap-offset: -12px;
  --mat-badge-small-size-container-overlap-offset: -6px;
  --mat-badge-large-size-container-overlap-offset: -12px;
  --mat-badge-container-padding: 0 4px;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0 4px;
  --mat-badge-small-size-text-size: 0;
  --mat-bottom-sheet-container-text-color: #162b35;
  --mat-bottom-sheet-container-background-color: #f4f4ee;
  --mat-bottom-sheet-container-text-font: "Roboto", sans-serif;
  --mat-bottom-sheet-container-text-line-height: 1.5rem;
  --mat-bottom-sheet-container-text-size: 1rem;
  --mat-bottom-sheet-container-text-tracking: 0.031rem;
  --mat-bottom-sheet-container-text-weight: 400;
  --mat-bottom-sheet-container-shape: 28px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.08;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-standard-button-toggle-text-color: #162b35;
  --mat-standard-button-toggle-state-layer-color: #162b35;
  --mat-standard-button-toggle-selected-state-background-color: #d7e8cd;
  --mat-standard-button-toggle-selected-state-text-color: #121f0e;
  --mat-standard-button-toggle-disabled-state-text-color: rgba(26, 28, 24, 0.38);
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(26, 28, 24, 0.38);
  --mat-standard-button-toggle-disabled-selected-state-background-color: rgba(26, 28, 24, 0.12);
  --mat-standard-button-toggle-divider-color: #73796e;
  --mat-standard-button-toggle-label-text-font: "Roboto", sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 1.25rem;
  --mat-standard-button-toggle-label-text-size: 0.875rem;
  --mat-standard-button-toggle-label-text-tracking: 0.006rem;
  --mat-standard-button-toggle-label-text-weight: 500;
  --mat-standard-button-toggle-height: 40px;
  --mat-standard-button-toggle-shape: 9999px;
  --mat-standard-button-toggle-background-color: transparent;
  --mat-standard-button-toggle-disabled-state-background-color: transparent;
  --mat-datepicker-calendar-date-selected-state-text-color: #ffffff;
  --mat-datepicker-calendar-date-selected-state-background-color: #234352;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(26, 28, 24, 0.38);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #234352;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(26, 28, 24, 0.12);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(26, 28, 24, 0.08);
  --mat-datepicker-toggle-active-state-icon-color: #4283a5;
  --mat-datepicker-calendar-date-in-range-state-background-color: #d0e2ff;
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: #ecdcff;
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #d7e8cd;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #54634d;
  --mat-datepicker-toggle-icon-color: #4283a5;
  --mat-datepicker-calendar-body-label-text-color: #162b35;
  --mat-datepicker-calendar-period-button-text-color: #4283a5;
  --mat-datepicker-calendar-period-button-icon-color: #4283a5;
  --mat-datepicker-calendar-navigation-button-icon-color: #4283a5;
  --mat-datepicker-calendar-header-text-color: #4283a5;
  --mat-datepicker-calendar-date-today-outline-color: #234352;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(26, 28, 24, 0.38);
  --mat-datepicker-calendar-date-text-color: #162b35;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(26, 28, 24, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: #234352;
  --mat-datepicker-range-input-separator-color: #162b35;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(26, 28, 24, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(26, 28, 24, 0.38);
  --mat-datepicker-calendar-container-background-color: #e8e9e2;
  --mat-datepicker-calendar-container-text-color: #162b35;
  --mat-datepicker-calendar-text-font: "Roboto", sans-serif;
  --mat-datepicker-calendar-text-size: 1rem;
  --mat-datepicker-calendar-body-label-text-size: 0.875rem;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 0.875rem;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 0.875rem;
  --mat-datepicker-calendar-header-text-weight: 500;
  --mat-datepicker-calendar-container-shape: 16px;
  --mat-datepicker-calendar-container-touch-shape: 28px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-divider-color: transparent;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-divider-color: #c3c8bc;
  --mat-divider-width: 1px;
  --mat-expansion-container-background-color: #fff;
  --mat-expansion-container-text-color: #162b35;
  --mat-expansion-actions-divider-color: #c3c8bc;
  --mat-expansion-header-hover-state-layer-color: rgba(26, 28, 24, 0.08);
  --mat-expansion-header-focus-state-layer-color: rgba(26, 28, 24, 0.12);
  --mat-expansion-header-disabled-state-text-color: rgba(26, 28, 24, 0.38);
  --mat-expansion-header-text-color: #162b35;
  --mat-expansion-header-description-color: #4283a5;
  --mat-expansion-header-indicator-color: #4283a5;
  --mat-expansion-header-text-font: "Roboto", sans-serif;
  --mat-expansion-header-text-size: 1rem;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: 1.5rem;
  --mat-expansion-header-text-tracking: 0.009rem;
  --mat-expansion-container-text-font: "Roboto", sans-serif;
  --mat-expansion-container-text-line-height: 1.5rem;
  --mat-expansion-container-text-size: 1rem;
  --mat-expansion-container-text-tracking: 0.031rem;
  --mat-expansion-container-text-weight: 400;
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
  --mat-expansion-container-shape: 12px;
  --mat-expansion-legacy-header-indicator-display: none;
  --mat-expansion-header-indicator-display: inline-block;
  --mat-grid-list-tile-header-primary-text-size: 400 1rem / 1.5rem "Roboto", sans-serif;
  --mat-grid-list-tile-header-secondary-text-size: 400 0.875rem / 1.25rem "Roboto", sans-serif;
  --mat-grid-list-tile-footer-primary-text-size: 400 1rem / 1.5rem "Roboto", sans-serif;
  --mat-grid-list-tile-footer-secondary-text-size: 400 0.875rem / 1.25rem "Roboto", sans-serif;
  --mat-icon-color: inherit;
  --mat-sidenav-container-background-color: #fff;
  --mat-sidenav-container-text-color: #4283a5;
  --mat-sidenav-content-background-color: #fff;
  --mat-sidenav-content-text-color: #000;
  --mat-sidenav-scrim-color: rgba(44, 50, 41, 0.4);
  --mat-sidenav-container-shape: 16px;
  --mat-sidenav-container-elevation-shadow: none;
  --mat-sidenav-container-width: 360px;
  --mat-sidenav-container-divider-color: transparent;
  --mat-stepper-header-icon-foreground-color: #fff;
  --mat-stepper-header-selected-state-icon-background-color: #234352;
  --mat-stepper-header-selected-state-icon-foreground-color: #ffffff;
  --mat-stepper-header-edit-state-icon-background-color: #234352;
  --mat-stepper-header-edit-state-icon-foreground-color: #ffffff;
  --mat-stepper-container-color: #fff;
  --mat-stepper-line-color: #c3c8bc;
  --mat-stepper-header-hover-state-layer-color: rgba(47, 49, 45, 0.08);
  --mat-stepper-header-focus-state-layer-color: rgba(47, 49, 45, 0.12);
  --mat-stepper-header-label-text-color: #4283a5;
  --mat-stepper-header-optional-label-text-color: #4283a5;
  --mat-stepper-header-selected-state-label-text-color: #4283a5;
  --mat-stepper-header-error-state-label-text-color: #ba1a1a;
  --mat-stepper-header-icon-background-color: #4283a5;
  --mat-stepper-header-error-state-icon-foreground-color: #ba1a1a;
  --mat-stepper-container-text-font: "Roboto", sans-serif;
  --mat-stepper-header-label-text-font: "Roboto", sans-serif;
  --mat-stepper-header-label-text-size: 0.875rem;
  --mat-stepper-header-label-text-weight: 500;
  --mat-stepper-header-error-state-label-text-size: 0.875rem;
  --mat-stepper-header-selected-state-label-text-size: 0.875rem;
  --mat-stepper-header-selected-state-label-text-weight: 500;
  --mat-stepper-header-height: 72px;
  --mat-stepper-header-focus-state-layer-shape: 12px;
  --mat-stepper-header-hover-state-layer-shape: 12px;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #162b35;
  --mat-toolbar-container-background-color: #fff;
  --mat-toolbar-container-text-color: #000;
  --mat-toolbar-title-text-font: Roboto;
  --mat-toolbar-title-text-line-height: 1.75rem;
  --mat-toolbar-title-text-size: 1.25rem;
  --mat-toolbar-title-text-tracking: 0rem;
  --mat-toolbar-title-text-weight: 400;
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
  --mat-tree-container-background-color: #fff;
  --mat-tree-node-text-color: #162b35;
  --mat-tree-node-text-font: "Roboto", sans-serif;
  --mat-tree-node-text-size: 1rem;
  --mat-tree-node-text-weight: 400;
  --mat-tree-node-min-height: 48px;
}

.mat-mdc-radio-button {
  --mat-checkbox-label-text-color: #162b35;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #162b35;
  --mdc-radio-disabled-unselected-icon-color: #162b35;
  --mdc-radio-unselected-hover-icon-color: #162b35;
  --mdc-radio-unselected-icon-color: rgba(17, 2, 70, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(17, 2, 70, 0.54);
  --mdc-radio-selected-focus-icon-color: #162b35;
  --mdc-radio-selected-hover-icon-color: #162b35;
  --mdc-radio-selected-icon-color: #162b35;
  --mdc-radio-selected-pressed-icon-color: #162b35;
  --mat-radio-ripple-color: #162b35;
  --mat-radio-checked-ripple-color: #162b35;
  --mat-radio-disabled-label-color: rgba(17, 2, 70, 0.5);
}
